import styled from 'styled-components';
import {
	black,
	focusOutlineColor,
	marigoldLinkColor,
	marigoldLinkColorHover,
	mintLinkColor,
	mintLinkColorHover,
	primaryLinkColor,
	primaryLinkColorHover,
	tapTransparent,
	white,
	grayDark,
} from '../../design-system/variables/colors';

const colors = {
	primary: {
		default: primaryLinkColor,
		hover: primaryLinkColorHover,
	},
	mint: {
		default: mintLinkColor,
		hover: mintLinkColorHover,
	},
	marigold: {
		default: marigoldLinkColor,
		hover: marigoldLinkColorHover,
	},
	black: {
		default: black,
		hover: black,
	},
	gray: {
		default: grayDark,
		hover: black,
	},
	white: {
		default: white,
		hover: white,
	},
	grayDark: {
		default: grayDark,
		hover: grayDark,
	},
};

export const Link = styled.a.attrs(props => ({
	default: colors[props.color].default,
	hover: colors[props.color].hover,
}))`
	color: ${props => props.default};
	cursor: pointer;
	text-decoration: none;
	${tapTransparent}

	&:visited {
		color: ${props => props.default};
	}

	&:hover {
		color: ${props => props.hover};
		text-decoration: underline;
	}

	&:active {
		color: ${props => props.default};
		text-decoration: underline;
	}

	&:focus {
		text-decoration: underline;
	}

	&[data-focus-visible-added] {
		outline: 2px solid ${focusOutlineColor};
		outline-offset: 2px;
	}
`;
