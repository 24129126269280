import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { white } from '../../design-system/variables/colors';
import { spaceBase, spaceLarge } from '../../design-system/variables/spaces';
import { md } from '../../design-system/media-queries';
import { Icon } from '../Icon';

const GlobalStyle = createGlobalStyle`
 .responsive-footer__social-icons-container {
	    width: 100%;
		max-width: 217px;
		margin: 0 auto ${spaceLarge} auto;

		${md`
			margin-bottom: ${spaceBase};
		`}

		.row {
			flex-wrap: nowrap;
		}
	}

 .responsive-footer__social-icons {
		padding: 0;
 }

	.responsive-footer__social-icon {
		display: flex;
    	justify-content: center;
		fill: ${white};
		line-height: 0;
	}

.tiktok-icon {
	height: 24px;
}
`;

export const SocialMedia: React.FC = () => (
	<div className='col-xs-12'>
		<GlobalStyle />
		<div className='responsive-footer__social-icons-container'>
			<div className='row center-xs'>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.instagram.com/inspirato/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='instagram' iconSize='md' />
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.facebook.com/Inspirato/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='facebook' iconSize='md' />
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='tiktok-icon responsive-footer__social-icon'
						href='https://www.tiktok.com/@inspirato'
						target='_blank'
						rel='noopener noreferrer'
					>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
							<path
								className='ds-shape-primary'
								d='M18.3 6.01163C17.16 5.31395 16.38 4.2093 16.14 2.87209C16.14 2.5814 16.08 2.2907 16.08 2H12.54V15.7791C12.48 17.3488 11.16 18.5698 9.54 18.5698C7.92 18.5698 8.58 18.4535 8.16 18.2209C7.2 17.7558 6.54 16.7674 6.54 15.6628C6.54 14.093 7.86 12.7558 9.54 12.7558C11.22 12.7558 10.14 12.7558 10.44 12.8721V9.38372C10.14 9.38372 9.84 9.32558 9.54 9.32558C5.94 9.32558 3 12.1744 3 15.6628C3 19.1512 4.08 19.6744 5.82 20.8372C6.9 21.5349 8.16 22 9.54 22C13.14 22 16.08 19.1512 16.08 15.6628V8.68605C17.46 9.67442 19.2 10.1977 21 10.1977V6.76744C19.98 6.76744 19.08 6.47674 18.3 6.01163Z'
							/>
						</svg>
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.youtube.com/user/Inspiratodotcom'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='youtube' iconSize='md' />
					</a>
				</div>
			</div>
		</div>
	</div>
);
