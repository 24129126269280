import { css } from 'styled-components';
import { gtAmerica, sabon } from '../../../src/design-system/variables/fonts';

/* Font family, weights & styles */
export const fontSans = css`
	font-family: ${gtAmerica};
`;
export const fontSerif = css`
	font-family: ${sabon};
`;

export const lightWeight = css`
	font-weight: 100;
`;
export const bold = css`
	font-weight: 700;
`;
export const italic = css`
	font-style: italic;
`;

export const textTiny = css`
	font-size: 11px;
`;
export const textSmall = css`
	font-size: 13px;
`;
export const textBase = css`
	font-size: 16px;
`;
export const textBaseSerif = css`
	font-size: 18px;
`;
export const textMedium = css`
	font-size: 20px;
`;
export const textLarge = css`
	font-size: 24px;
`;
export const textBig = css`
	font-size: 28px;
`;
export const textHuge = css`
	font-size: 36px;
`;
export const textMassive = css`
	font-size: 44px;
`;
