import React, { Fragment, memo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { white, dividerGrayLight } from '../../design-system/variables/colors';
import { spaceBig, spaceLarge, spaceBase, spaceMedium, spaceTiny } from '../../design-system/variables/spaces';
import { Icon } from '../Icon';
import { SocialMedia } from './SocialMedia';
import { Paragraph } from '../Paragraph';
import { lg, md, sm, smOnly } from '../../design-system/media-queries';

const GlobalStyle = createGlobalStyle`
	.sub-footer__container {
		margin: 0 auto;
		padding-left: ${spaceBase};
		padding-right: ${spaceBase};
		padding-bottom: ${spaceBig};
		padding-top: ${spaceLarge};
		width: 100%;
		max-width: 1200px;

		${sm`
			padding-left: ${spaceLarge};
			padding-right: ${spaceLarge};
			border-top: ${dividerGrayLight};
		`}
		${smOnly`
			padding-top: ${spaceBig};
		`}

		p {
			margin: ${spaceMedium} 0 0;

			${md`
				margin-top: 0;
			`}
		}
	}

	.sub-footer__gap {
		${md`
			gap: ${spaceLarge};
			padding-left: ${spaceBase};
			padding-right: ${spaceBase};
		`}

		${lg`
			gap: ${spaceBig};
			padding-left: 0;
			padding-right: 0;
		`}
	}

	.sub-footer__link {
		display: inline-block;
		fill: ${white};
		line-height: 0;

		svg {
			width: 100%;
		}

		.ispo-logo {
			max-width: 77px;
			border-top: ${dividerGrayLight};
    		padding-top: ${spaceMedium};

			${md`
				border-top: none;
				padding-top: initial;
			`}
		}

		.right-logo {
			height: 44px;
			max-width: 228px;
			margin-bottom: ${spaceTiny};

			${md`
				height: 34px;
				max-width: 160px;
				margin-bottom: initial;
			`}
		}
	}

	.sub-footer__phone-link {
		&:hover {
			text-decoration: none;
		}
	}
`;

export const Subfooter: React.FC = memo(() => {
	const currentYear = new Date(Date.now()).getFullYear();

	return (
		<Fragment>
			<GlobalStyle />
			<section className='sub-footer__container'>
				<div className='row middle-xs center-xs'>
					<SocialMedia />
				</div>
				<div className='row center-xs between-md sub-footer__gap'>
					<div className='col-xs-12 col-md center-xs end-lg'>
						<a
							className='sub-footer__link'
							color='white'
							href='https://investor.inspirato.com/'
							target='_blank'
							title='Go to Inspirato Investor Relations'
							rel='noreferrer'
						>
							<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 825.13 406.71' className='ispo-logo'>
								<path
									d='M1114.51,322.32a304.35,304.35,0,0,1-1.85,33.21c-1.6,13.93-4.38,27.59-10.12,40.48-4.91,11-11.8,20.55-21.55,27.84-8.58,6.42-18.27,10.36-28.63,12.76a136.28,136.28,0,0,1-24,3.09,166.86,166.86,0,0,1-24.39-.59c-12.61-1.22-24.79-4.06-36-10.14-13.27-7.16-22.71-17.92-29.17-31.4-5-10.46-7.95-21.56-9.86-33-2.81-16.83-3.29-33.79-3-50.8a247.26,247.26,0,0,1,2.13-29.17c1.79-13.13,4.76-25.93,10.44-38,9-19.19,23.81-31.59,44.08-37.57a116.36,116.36,0,0,1,24.64-4.28,177.45,177.45,0,0,1,27.57.1c12.39,1,24.44,3.5,35.71,9,13.34,6.53,23.16,16.62,29.94,29.76a112.59,112.59,0,0,1,10.11,30.68,206.67,206.67,0,0,1,3.48,31.78C1114.21,311.52,1114.35,316.92,1114.51,322.32Zm-32.6-3.46c-.11-3.15-.27-6.3-.34-9.46a191.47,191.47,0,0,0-2.6-28.28,85.64,85.64,0,0,0-6.24-21.35c-3.89-8.47-9.58-15.33-17.88-19.8a54,54,0,0,0-18-5.63,119.86,119.86,0,0,0-21.42-1,87.21,87.21,0,0,0-18.14,2.29c-9.18,2.28-17.14,6.57-23.19,14a54.43,54.43,0,0,0-9.42,18.45c-3.4,10.87-4.8,22.07-5.39,33.37-.42,8.06-.49,16.14-.53,24.21a213.6,213.6,0,0,0,2.58,35.45,90.44,90.44,0,0,0,6.3,22.21c5.72,12.89,15.33,21.2,29,24.72a84.26,84.26,0,0,0,19.16,2.52c8.3.19,16.58,0,24.74-1.74a46.67,46.67,0,0,0,19.15-8.31c6.66-5.11,11-11.87,14.13-19.56,4-9.94,5.7-20.38,6.74-30.95C1081.62,339.64,1081.9,329.26,1081.91,318.86Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M767.22,435.49h-31V208c.68-.05,1.3-.12,1.91-.12,24.79,0,49.59-.09,74.38,0,11.67.06,23.29,1,34.62,4.06a71.33,71.33,0,0,1,15.24,6c12,6.53,19.91,16.41,24.29,29.24a89.39,89.39,0,0,1,4.39,24.57c.6,11.69,0,23.29-3.11,34.64-4.09,14.68-12.39,26.13-25.85,33.59a77.34,77.34,0,0,1-23.58,8.11,135.21,135.21,0,0,1-24.92,2.21h-46.4c0,5.88,0,11.54,0,17.2s0,11.23,0,16.85v51.12ZM767.34,324c.7,0,1.25.08,1.8.08,15.53,0,31.05.05,46.58,0A73.75,73.75,0,0,0,833,322c13-3.22,21.28-11.22,24.3-24.33A84.46,84.46,0,0,0,859.07,286a77.42,77.42,0,0,0-1.44-23.66c-2.9-12.77-10.53-21.1-23.24-24.66A72.4,72.4,0,0,0,815,235.14c-15.17,0-30.35,0-45.52,0-.69,0-1.37.06-2.17.1Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M683.7,216.49c-1.42,9-2.83,18-4.25,27-3.51-1.22-6.73-2.39-10-3.47A136.77,136.77,0,0,0,620,232.76c-8,.35-15.92,1.66-23.28,5.15-9.18,4.35-15.3,11.25-17.32,21.39q-3.74,18.78,11.9,29.92A80.73,80.73,0,0,0,610,298.74c7.88,2.95,15.84,5.69,23.76,8.52a169.67,169.67,0,0,1,26,11.28A67.73,67.73,0,0,1,679,333.8a50.43,50.43,0,0,1,11.57,26.45,78.35,78.35,0,0,1-3.85,37.94c-6.28,16.93-18.24,28.33-34.83,35.06a90.33,90.33,0,0,1-25.52,6,138.31,138.31,0,0,1-54.51-5.58,120.47,120.47,0,0,1-23.34-10.06c-.54-.31-1.06-.65-1.78-1.09l4.84-28.17c.75.38,1.3.63,1.81.93a115.37,115.37,0,0,0,37,14.15,123.13,123.13,0,0,0,17.13,2,91,91,0,0,0,21.8-1.33,47.62,47.62,0,0,0,15-5.4c8.94-5.19,14.19-13,16.09-23.05a39.44,39.44,0,0,0,.28-14.65c-1.41-7.58-5.67-13.33-11.63-18a72.86,72.86,0,0,0-18.82-10.19c-9-3.48-18.15-6.72-27.23-10.08s-18.31-7.13-26.73-12.29a65.28,65.28,0,0,1-15-12.21,48.72,48.72,0,0,1-12-26.43,69.42,69.42,0,0,1,3.42-34c5.67-15.26,16.37-25.74,31.14-32.24a82.34,82.34,0,0,1,24.56-6.37,153.33,153.33,0,0,1,62,6A86.33,86.33,0,0,1,683.7,216.49Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M463.58,208H495V435.25c-1.42.44-28.52.59-31.42.17C463.15,433.76,463.12,209.94,463.58,208Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M445.2,591.79H433.5c-1,0-1.25-.81-1.67-1.41q-5.7-8.1-11.38-16.21-16-23-32-46a17.12,17.12,0,0,0-2.43-3v66.5H370.38V495.86c.58-.05,1.18-.13,1.78-.14,3.86,0,7.73.08,11.59,0a3.21,3.21,0,0,1,3.07,1.67q10.71,15.76,21.47,31.5,10,14.63,19.91,29.26c.38.57.81,1.1,1.46,2v-64c1.64-.46,12.91-.56,15.54-.15Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M644.22,520.84V491.5l15.83-.91c.48,1.6.7,95.7.25,100.87-1.44.42-9.89.53-12.64.14l-1.81-7.06a8.1,8.1,0,0,0-1.16.9c-5,5.48-11.52,7.53-18.7,7.89a37.55,37.55,0,0,1-10.67-.9,23.47,23.47,0,0,1-16.54-13.75,46.59,46.59,0,0,1-3.73-14.19,93.3,93.3,0,0,1,0-21.84,43.32,43.32,0,0,1,5.11-16.8,23.53,23.53,0,0,1,15.95-11.75c6.6-1.44,13.15-1.28,19.43,1.38a57.39,57.39,0,0,1,6.9,4A22.67,22.67,0,0,1,644.22,520.84Zm-.1,33.06c0-5.12-.19-10.25.07-15.36a6.91,6.91,0,0,0-3.31-6.51,21.39,21.39,0,0,0-2.33-1.43c-4-2.09-8.26-2.47-12.67-2.14-6.46.48-10.69,3.76-12.68,9.91-.21.67-.39,1.35-.54,2a72.4,72.4,0,0,0-.52,24.48,30.69,30.69,0,0,0,2,7.26,11.28,11.28,0,0,0,8.43,6.92,25.93,25.93,0,0,0,9.81.33,16.37,16.37,0,0,0,10.38-5.48,5.44,5.44,0,0,0,1.39-3.81C644.08,564.7,644.12,559.3,644.12,553.9Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M795.71,586.5l-1.28.85c-10,6.93-20.68,7.31-31.63,2.85-5.85-2.38-9.7-7-12.37-12.67a46.06,46.06,0,0,1-3.94-15,79.52,79.52,0,0,1,1.12-25.33,38.55,38.55,0,0,1,3.54-9.87,24.2,24.2,0,0,1,15.12-12.55,36.63,36.63,0,0,1,21.57.26,24.7,24.7,0,0,1,10.3,6.32c.28.29.59.53,1.1,1L801.3,515h10.34v96h-16.1C795.33,603,795.48,595,795.71,586.5Zm-.25-33.12c0-5-.16-10,.06-15a6.64,6.64,0,0,0-3.14-6.19,18.62,18.62,0,0,0-3.06-1.83,24,24,0,0,0-16.07-1.11,10.8,10.8,0,0,0-7.47,6.28,24.36,24.36,0,0,0-1.7,5.18,71.85,71.85,0,0,0-.68,20.55,28.91,28.91,0,0,0,2.27,9.36,12.69,12.69,0,0,0,9.43,7.62,25.12,25.12,0,0,0,8.6.27,16.35,16.35,0,0,0,9.47-4.17,6.92,6.92,0,0,0,2.32-5.41C795.4,563.77,795.46,558.57,795.46,553.38Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M733.63,591.73H721.07c-.48-2.62-1-5.25-1.5-8.12a9.47,9.47,0,0,0-.94.75c-5.2,5.78-11.86,8.31-19.46,8.68a31.71,31.71,0,0,1-9.2-.79c-7.69-1.91-12.69-6.62-15-14.2a32.55,32.55,0,0,1-.87-15.41c1.52-8.18,6.38-13.56,14.09-16.43a38.71,38.71,0,0,1,17.86-1.87c3.68.41,7.32,1.16,11.22,1.8-.08-3.73.38-7.57-.88-11.31a9.44,9.44,0,0,0-5.81-6,23.1,23.1,0,0,0-7.84-1.36,49.65,49.65,0,0,0-21.8,3.83,9.39,9.39,0,0,1-1.19.33c-.47-4.47-.94-8.84-1.41-13.31a38.15,38.15,0,0,1,8.54-3,65.39,65.39,0,0,1,27.77-1.1,27.73,27.73,0,0,1,5,1.48c7.2,2.79,11,8.38,12.67,15.66a55.78,55.78,0,0,1,1.2,12.34c.06,15.31,0,30.63,0,45.94Zm-16.37-33.88c-.83-.28-1.47-.53-2.14-.72a35.44,35.44,0,0,0-12.28-1.31,29.43,29.43,0,0,0-6.4,1.07,9.51,9.51,0,0,0-6.81,6.84,15.29,15.29,0,0,0,.35,9.32,8.78,8.78,0,0,0,5.58,5.65c6.85,2.31,13.14,1.21,18.7-3.48a7.5,7.5,0,0,0,3-5.4C717.35,565.91,717.26,562,717.26,557.85Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M519.54,591.72H507.09c-.5-2.63-1-5.26-1.53-8.11a9.57,9.57,0,0,0-.84.67c-5.12,5.72-11.67,8.31-19.2,8.73a30.51,30.51,0,0,1-11.23-1.24A19,19,0,0,1,461.69,580a31.52,31.52,0,0,1-1.35-18.22c1.8-8.21,7.11-13.34,15-15.92a38.67,38.67,0,0,1,16.65-1.51c3.41.39,6.76,1.19,10.14,1.8.27,0,.55.06,1,.1a7.19,7.19,0,0,0,.25-1.38c0-2.39,0-4.79-.22-7.15a10,10,0,0,0-7.63-9.27c-3.78-1.13-7.64-1-11.49-.9a46.5,46.5,0,0,0-16.76,3.7c-.37.15-.76.25-1.39.45l-1.43-13.54c2.34-.8,4.61-1.71,6.95-2.37a64.6,64.6,0,0,1,29.38-1.56c5.54,1,10.39,3.4,13.81,8.12a22.94,22.94,0,0,1,4.08,10,67.65,67.65,0,0,1,.9,9.83c.1,15.59,0,31.18,0,46.76Zm-16.23-27.46c0-1.47,0-3,0-4.42,0-2.07,0-2.09-2.09-2.69a39.21,39.21,0,0,0-12.48-1.36,20,20,0,0,0-7.38,1.48,9.78,9.78,0,0,0-6.09,8.63,24,24,0,0,0,0,3.79,9.87,9.87,0,0,0,7.81,9.49,17.42,17.42,0,0,0,3.51.6,18.48,18.48,0,0,0,13.14-4.06,8.9,8.9,0,0,0,3.61-7.68C503.26,566.79,503.31,565.52,503.31,564.26Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M581.81,517.78c-.47,4.51-.93,8.94-1.4,13.41-3.12-.87-6-1.79-8.9-2.46a42.18,42.18,0,0,0-14.42-1.05,15.34,15.34,0,0,0-5.19,1.54c-5.19,2.73-5.47,8.89-.53,12a30,30,0,0,0,6.09,2.8c3.76,1.36,7.61,2.44,11.36,3.83a42.19,42.19,0,0,1,6.45,3.05,18.2,18.2,0,0,1,9.48,14.58A29,29,0,0,1,583,579.18c-2.57,6.56-7.55,10.47-14.13,12.52a43.54,43.54,0,0,1-14.58,1.63c-7.16-.25-14.07-1.54-20.48-4.94-.37-.19-.73-.41-1.32-.74.31-4.5,1.18-9,1.79-13.77a13.37,13.37,0,0,1,1.39.58,42.58,42.58,0,0,0,17.65,5.07,24.87,24.87,0,0,0,8.77-.53c3.5-1,6.25-2.84,7.22-6.62.91-3.57,0-6.42-3-8.5a27.66,27.66,0,0,0-5.78-3c-3.93-1.49-8-2.74-11.92-4.15a33.68,33.68,0,0,1-8.76-4.51,18.35,18.35,0,0,1-7.73-15.15,23.52,23.52,0,0,1,2-10.67c2.78-6,7.7-9.5,13.86-11.39a42.68,42.68,0,0,1,14.79-1.64,54.07,54.07,0,0,1,16.63,3.34C580.2,517,581,517.4,581.81,517.78Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M1187.76,490l7.75-.64V591.65h-5.88c-.45-2.86-.89-5.66-1.38-8.82-.88,1-1.46,1.71-2.06,2.37a23.62,23.62,0,0,1-12.41,7.11,40.37,40.37,0,0,1-19-.08c-7.42-1.82-12.5-6.37-15.53-13.31a45.1,45.1,0,0,1-3.36-13.64,103.08,103.08,0,0,1,0-21.65,46.25,46.25,0,0,1,3.37-13.64c3.32-7.6,8.9-12.5,17.08-14.21a36.27,36.27,0,0,1,20.52,1.28,26,26,0,0,1,9.49,6.1c.37.38.77.74,1.43,1.37Zm0,64.44c0-6.67,0-13.34,0-20a4.59,4.59,0,0,0-.94-2.91,20,20,0,0,0-8.51-6.67,30.63,30.63,0,0,0-20.8-1.11,15.18,15.18,0,0,0-9.88,8,38,38,0,0,0-2.84,7.64c-1.53,6.11-1.49,12.37-1.35,18.62a55.12,55.12,0,0,0,2.13,15.16c2,6.51,6,11,12.83,12.64a33,33,0,0,0,15.65,0,20.75,20.75,0,0,0,12.54-8.27,6.05,6.05,0,0,0,1.18-3.7C1187.69,567.3,1187.73,560.84,1187.73,554.39Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M1121,553.71h-50.2c-.15.34-.3.52-.29.7a52.66,52.66,0,0,0,2.6,17.4c2.77,7.92,8.42,12.37,16.66,13.59a42.56,42.56,0,0,0,26.58-4.14c.31-.16.64-.29,1-.42a3.22,3.22,0,0,1,.49,0l1,6.54a31,31,0,0,1-6.68,3.2,47.83,47.83,0,0,1-28.38,1.32c-9.28-2.31-15.17-8.37-18.26-17.3a54.28,54.28,0,0,1-2.76-14.21c-.5-7.57-.68-15.14,1-22.63a37,37,0,0,1,3.27-9.07,24.08,24.08,0,0,1,14.81-12.31,36.48,36.48,0,0,1,18.12-.72c9,1.86,15.17,7.28,18.53,15.8,2.62,6.64,3.25,13.6,3,20.68a3.82,3.82,0,0,1-.15.82A6.32,6.32,0,0,1,1121,553.71Zm-6.76-5.73a39.83,39.83,0,0,0-2.33-13.58c-2.51-6.43-7-10.51-13.86-11.91a29.67,29.67,0,0,0-8.36-.41q-13.08,1-17.27,13.39a38.39,38.39,0,0,0-2,12.51Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M1000.24,519.37c-.24,2.14-.47,4.28-.73,6.66-.74-.24-1.32-.4-1.87-.62a45.83,45.83,0,0,0-17.22-3.5,22.5,22.5,0,0,0-12,2.77A11,11,0,0,0,963,536.86a8.8,8.8,0,0,0,3.55,5.55,28.93,28.93,0,0,0,5.81,3.33c4,1.58,8.06,2.85,12.1,4.24a44.93,44.93,0,0,1,10.85,5,17,17,0,0,1,7.92,14.73,36.77,36.77,0,0,1-.6,7.11c-1.51,7.53-6.07,12.46-13.34,14.89a36.76,36.76,0,0,1-13.3,1.6,39.66,39.66,0,0,1-16.83-4.12c-1.18-.61-2.29-1.37-3.42-2.06a1.4,1.4,0,0,1-.79-1.56c.37-2,.72-4,1.12-6.16l1.74,1.11a34.82,34.82,0,0,0,26.35,5.23c8.58-1.66,13.47-9.44,11.35-17.61a11.56,11.56,0,0,0-5.66-7.29,60,60,0,0,0-7.77-3.69c-3.52-1.39-7.17-2.44-10.69-3.83a54.25,54.25,0,0,1-7.59-3.59c-6.23-3.64-9-9.19-8.57-16.38.47-7.38,4-12.7,10.76-15.81a27.89,27.89,0,0,1,8.92-2.34c8.31-.84,16.39,0,24.08,3.47C999.4,518.87,999.75,519.1,1000.24,519.37Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M864.34,583.46h47.51c.36,2.85-.09,5.46-.23,8.21h-55.4c-.41-1.42-.58-92.43-.15-95.8h8.27Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M1025.85,522.63h-11.67v-6.39h11.75c.53-6.7,1-13.18,1.58-19.86l6.27-.8v20.58h19.66v6.37H1034a17.26,17.26,0,0,0-.2,1.76q0,25,0,49.93a17.65,17.65,0,0,0,.48,4,9.52,9.52,0,0,0,7.78,7.63,20.12,20.12,0,0,0,8.77-.15l1.59-.32c.21,2.07.41,4,.64,6.13-1,.28-1.81.56-2.67.76a27.2,27.2,0,0,1-11.91.24c-6.76-1.38-11-5.82-12.16-12.86a33.13,33.13,0,0,1-.46-5.45q-.06-24.33,0-48.67Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M936.7,591.7h-7.45c-.47-1.52-.68-69.92-.2-75.1l7.65-.66Z'
									transform='translate(-370.38 -204.29)'
								/>
								<path
									d='M932.57,500.75h-.63c-3.46-.19-5.07-1.75-5.16-5s1.69-5.41,5.07-5.69a12.4,12.4,0,0,1,2.51.07,4.79,4.79,0,0,1,4.57,5c.11,3.36-1.57,5.28-4.89,5.57-.49,0-1,0-1.47.07Z'
									transform='translate(-370.38 -204.29)'
								/>
							</svg>
						</a>
					</div>
					<div className='col-xs-11 col-sm-7 center-xs'>
						<Paragraph size='small'>
							Inspirato&reg; is owned by Inspirato&nbsp;LLC. All&nbsp;products and services offered
							by&nbsp;Inspirato&reg; are provided solely by Inspirato&nbsp;LLC or its agents
							and&nbsp;affiliates. &copy;&nbsp;Copyright&nbsp;{currentYear} Inspirato&nbsp;LLC.
							All&nbsp;rights reserved.&nbsp;
							<a className='sub-footer__phone-link' href='tel:(303) 586-7771'>
								(303)&nbsp;586&#8209;7771
							</a>
						</Paragraph>
					</div>
					<div className='col-xs-12 col-md center-xs first-xs last-md'>
						<a className='sub-footer__link' color='white' href='/' title='Go to the Inspirato Home Page'>
							<Icon iconName='logo-inspirato' className='right-logo' />
						</a>
					</div>
				</div>
			</section>
		</Fragment>
	);
});
