import React, { MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import {
	black,
	blackHover,
	disabled,
	focusOutlineColor,
	grayDark,
	tapTransparent,
	white,
	whitePressed,
	gray,
} from '../../design-system/variables/colors';
import { spaceBig } from '../../design-system/variables/spaces';
import { Icon, svgSizes } from './Icon';
import { transparentDark15 } from '../../design-system/style-mixins/shadows-and-scrims';

/* Button Sizes */

const large = css`
	width: ${spaceBig};
	height: ${spaceBig};
`;
const base = css`
	width: 40px;
	height: 40px;
`;

const small = css`
	width: 20px;
	height: 20px;
`;

/* Button Shapes */
const square = css`
	border-radius: 0;
`;

const round = css`
	border-radius: 50%;
`;

/* Button Styles */

const primary = css`
	background: ${black};
	border-color: ${black};

	svg {
		fill: ${white};
	}

	&:hover {
		background: ${blackHover};
		border-color: ${blackHover};
	}
	&:active {
		background: ${black};

		svg {
			color: ${white};
		}
	}
`;

const primaryInvert = css`
	background: ${white};
	border-color: ${white};

	svg {
		fill: ${black};
	}

	&:hover {
		background: ${blackHover};
		border-color: ${blackHover};

		svg {
			fill: ${white};
		}
	}

	&:active {
		background: ${black};

		svg {
			color: ${white};
		}
	}
`;

const secondary = css`
	background: transparent;
	border-color: ${black};

	svg {
		fill: ${black};
	}

	&:hover {
		background: ${blackHover};
		border-color: ${blackHover};

		svg {
			fill: ${white};
		}
	}

	&:active {
		background: ${black};

		svg {
			color: ${white};
		}
	}
`;

const secondaryInvert = css`
	background: transparent;
	border: ${white} 1px solid;

	svg {
		fill: ${white};
	}

	&:hover {
		background: ${white};

		svg {
			fill: ${black};
		}
	}

	&:active {
		background-color: ${whitePressed};
		border: ${whitePressed} 1px solid;

		svg {
			fill: ${black};
		}
	}
`;

const grayRounded = css`
	background: ${gray};
	border-color: ${gray};

	svg {
		fill: ${white};
	}

	&:hover {
		background: ${gray};
		border-color: ${gray};
	}

	&:active {
		background: ${gray};

		svg {
			color: ${white};
		}
	}
`;

const containerless = css`
	background: transparent;
	border-color: transparent;

	svg {
		fill: ${grayDark};
	}

	&:hover {
		svg {
			fill: ${blackHover};
		}
	}

	&:active {
		svg {
			fill: ${black};
		}
	}
`;

const containerlessPrimary = css`
	background: transparent;
	border-color: transparent;

	svg {
		fill: ${white};
	}
`;

const containerlessSecondary = css`
	background: transparent;
	border-color: transparent;

	svg {
		fill: ${black};
	}
`;

const squarePrimary = css`
	background: rgba(0, 0, 0, 0.75);
	border-radius: 0;

	svg {
		fill: ${whitePressed};
		z-index: 100;
	}

	&:hover {
		background: rgba(0, 0, 0, 0.95);

		svg {
			fill: ${white};
		}
	}
`;

const isDisabledStyles = css`
	&& {
		background: transparent;
		border: ${disabled} 1px solid;
		cursor: not-allowed;

		svg {
			fill: ${disabled};
		}

		&:active {
			transform: translateY(0);
		}
	}
`;

const buttonSizeStyles = {
	large,
	base,
	small,
};

const buttonShapeStyles = {
	square,
	round,
};

const typeStyles = {
	primary,
	primaryInvert,
	secondary,
	secondaryInvert,
	containerless,
	containerlessPrimary,
	containerlessSecondary,
	squarePrimary,
	grayRounded,
};

const enableFloatStyles = css`
	box-shadow: 0 2px 8px ${transparentDark15};
	transform: translateY(4px);
	transition: background-color 100ms ease-out, box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
		transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

	&:hover {
		transform: translateY(0);
		box-shadow: 0 4px 16px ${transparentDark15};
	}
`;

interface CircleContainerProps {
	enableFloat?: boolean;
	buttonSize?: iconButtonSizes;
	buttonShape?: iconButtonShapes;
	buttonType?: iconButtonTypes;
	onClick?: Function | ((e: React.MouseEvent<HTMLElement>) => void);
}

const CircleContainer = styled.button<CircleContainerProps>`
	${tapTransparent}
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	border-style: solid;
	border-width: 1px;
	outline: none;
	text-decoration: none;
	transition: background-color 100ms ease-out;
	touch-action: manipulation;

	svg {
		display: flex;
		transition: fill 100ms ease-out;
	}

	&:active {
		transform: translateY(1px);
		transition-duration: 0s;
	}

	&[data-focus-visible-added] {
		outline: 2px solid ${focusOutlineColor};
		outline-offset: 2px;
	}

	&:disabled {
		${isDisabledStyles}

		${props =>
			props.buttonType === 'containerless' &&
			css`
				&& {
					border-color: transparent;
				}
			`}
	}

	${props => buttonSizeStyles[props.buttonSize] || buttonSizeStyles.base}
	${props => buttonShapeStyles[props.buttonShape] || buttonShapeStyles.round}
	${props => typeStyles[props.buttonType] || typeStyles.secondary}
	${props =>
		props.enableFloat &&
		css`
			${enableFloatStyles}
		`}
`;

type iconButtonTypes =
	| 'primary'
	| 'primaryInvert'
	| 'secondary'
	| 'secondaryInvert'
	| 'containerless'
	| 'containerlessPrimary'
	| 'containerlessSecondary'
	| 'squarePrimary'
	| 'grayRounded';

type iconButtonSizes = 'base' | 'large' | 'small';

type iconButtonShapes = 'square' | 'round';

export interface IconButtonProps {
	className?: string;
	iconSize?: svgSizes;
	iconName: string;
	buttonShape?: iconButtonShapes;
	buttonSize?: iconButtonSizes;
	enableFloat?: boolean;
	buttonType?: iconButtonTypes;
	height?: string;
	width?: string;
	onClick?: any;
	title?: string;
	isDisabled?: boolean;
}

/**
 * Icon Button:
 * Button with icon in a circle.
 * Can adjust icon size, button size, button type, make a "floating" button, make a "containerless" button.
 */
export const IconButton = ({ className, iconName, iconSize, title, ...rest }: IconButtonProps) => (
	<CircleContainer {...rest} className={className} type='button' title={title || null} disabled={rest.isDisabled}>
		<Icon {...{ iconName, iconSize }} />
	</CircleContainer>
);
