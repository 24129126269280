import React, { Fragment } from 'react';
import { FooterColumnProps } from './FooterColumnProps';
import { Icon } from '../../Icon';
import { Link } from '../../Link';

export const FooterColumn: React.FC<FooterColumnProps> = ({ columnHeader, links = [] }) => (
	<Fragment>
		<input className='responsive-footer__input' type='checkbox' />
		<div className='responsive-footer__heading'>
			{columnHeader}
			<div className='responsive-footer__icon-container'>
				<Icon iconName='arrow' iconSize='sm' />
			</div>
		</div>
		<ul className='responsive-footer__list'>
			{links.map(({ linkTitle, url, componentName }) => {
				if (componentName === 'call-to-action') {
					return (
						<li className='responsive-footer__list-item'>
						<button
							className='responsive-footer__button-item'
							onClick={(e) => {
								e.stopPropagation();
								(window as any).OneTrust.ToggleInfoDisplay();
							}}
						>
							{linkTitle}
						</button>
						</li>
					);
				} else {
					return (
						<li className='responsive-footer__list-item' key={linkTitle}>
							<Link color='white' href={url}>
								{linkTitle}
							</Link>
						</li>
					);
				}
			})}
		</ul>
	</Fragment>
);
