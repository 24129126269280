import { css } from 'styled-components';

export const sizesInEms = {
	sm: 48, // 768 px
	md: 64, // 1024 px
	lg: 75, // 1200 px
};

export const minWidths = Object.entries(sizesInEms).reduce(
	(acc, [name, value]) => ({
		...acc,
		[name]: `(min-width: ${value}em)`,
	}),
	{},
);

export const maxWidths = Object.entries(sizesInEms).reduce(
	(acc, [name, value]) => ({
		...acc,
		[name]: `(min-width: ${value - 0.01}em)`,
	}),
	{},
);

/**
 * Flexbox Grid mixins:
 * These are the primary mixins to use for your media queries.
 * Mobile-first approach. xs is default.
 * USE OFTEN!
 */
export const xs = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (min-width: 30em) {
			${css(literals, ...placeholders)}
		}
	`;

export const sm = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (min-width: 48em) {
			${css(literals, ...placeholders)}
		}
	`;

export const md = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (min-width: 64em) {
			${css(literals, ...placeholders)}
		}
	`;

export const lg = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (min-width: 75em) {
			${css(literals, ...placeholders)}
		}
	`;

/**
 * Specific sections sections:
 * USE ONLY WHEN NECESSARY.
 */
export const xsOnly = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (max-width: 47.99em) {
			${css(literals, ...placeholders)}
		}
	`;
export const xsSmOnly = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (max-width: 63.99em) {
			${css(literals, ...placeholders)}
		}
	`;
export const smOnly = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (min-width: 48em) and (max-width: 63.99em) {
			${css(literals, ...placeholders)}
		}
	`;
export const smMdOnly = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (min-width: 48em) and (max-width: 74.99em) {
			${css(literals, ...placeholders)}
		}
	`;
export const mdOnly = (literals: TemplateStringsArray, ...placeholders: any[]) =>
	css`
		@media (min-width: 64em) and (max-width: 74.99em) {
			${css(literals, ...placeholders)}
		}
	`;
