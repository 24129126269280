import React, { Fragment } from 'react';
import { FooterProps } from './FooterProps';
import { Icon } from '../Icon';
import { FooterColumn } from './FooterColumn';

export const MainFooter: React.FC<FooterProps> = ({ footerColumns }) => (
	<Fragment>
		<section className='responsive-footer__container'>
			<div className='row'>
				<div className='col-xs-12 col-sm-11 col-sm-offset-1 col-lg-10 col-lg-offset-2'>
					<div className='row'>
						<div className='col-xs-12 col-sm'>
							<FooterColumn {...footerColumns[0]} />
						</div>
						<div className='col-xs-12 col-sm'>
							<FooterColumn {...footerColumns[1]} />
						</div>
						<div className='col-xs-12 col-sm'>
							<FooterColumn {...footerColumns[2]} />
						</div>
						<div className='col-xs-12 col-sm'>
							<FooterColumn {...footerColumns[3]} />
						</div>
					</div>
				</div>
			</div>
		</section>
	</Fragment>
);
