/* Spacing Variables & Mixins
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/**
 * Margins & Padding
 * Use these variables for spacing.
 */

/**
 * @spaceTiny 0.25rem | 4px
 */
export const spaceTiny = '0.25rem';

/**
 * @spaceSmall 0.5rem | 8px
 */
export const spaceSmall = '0.5rem';

/**
 * @spaceBase 1rem | 16px
 */
export const spaceBase = '1rem';

/**
 * @spaceMedium 1.5rem | 24px
 */
export const spaceMedium = '1.5rem';

/**
 * @spaceLarge 2rem | 32px
 */
export const spaceLarge = '2rem';

/**
 * @spacBige 3rem | 48px
 */
export const spaceBig = '3rem';

/**
 * @spaceHuge 4rem | 64px
 */
export const spaceHuge = '4rem';

/**
 * @spaceMassive 5rem | 80px
 */
export const spaceMassive = '5rem';

/**
 * @spaceSuperMassive 6rem | 96px
 */
export const spaceSuperMassive = '6rem';

export const spaceBetween = (spaceVar1, spaceVar2) => {
	const val1 = parseFloat(spaceVar1.split('rem')[0]);
	const val2 = parseFloat(spaceVar2.split('rem')[0]);

	return `${Math.max(val1, val2) - (Math.max(val1, val2) - Math.min(val1, val2)) / 2}rem`;
};
