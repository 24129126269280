import React from 'react';
import styled, { css } from 'styled-components';
import { spaceLarge, spaceBase, spaceMedium } from '../../design-system/variables/spaces';

const sm = css`
	height: ${spaceBase};
	width: ${spaceBase};
`;

const md = css`
	height: ${spaceMedium};
	width: ${spaceMedium};
`;

const lg = css`
	height: ${spaceLarge};
	width: ${spaceLarge};
`;

const xlg = css`
	height: 40px;
	width: 40px;
`;

const sizeStyles = {
	sm,
	md,
	lg,
	xlg,
};

export type svgSizes = 'sm' | 'md' | 'lg' | 'xlg';

const Svg = styled.svg<{ iconSize: svgSizes }>`
	${props => sizeStyles[props.iconSize] || ''}
	${props => (props.width ? `width: ${props.width};` : '')}
	${props => (props.height ? `height: ${props.height};` : '')}
	${props => props.fill && `fill: ${props.fill} !important`}

	use {
		pointer-events: none;
	}
`;

interface IconProps {
	iconSize?: svgSizes;
	width?: string;
	height?: string;
	iconName: string;
	fill?: string;
	onClick?: () => void;
	className?: string;
}

interface CustomIconProps {
	iconSize?: svgSizes;
	width?: string;
	height?: string;
	fill?: string;
	onClick?: () => void;
	className?: string;
}

export const Icon = ({ iconName, iconSize, width, height, fill, ...rest }: IconProps) => (
	<Svg iconSize={iconSize} width={width} height={height} fill={fill} {...rest}>
		<use xlinkHref={`#ds-icon-${iconName}`} />
	</Svg>
);

// Icon is in serenity-web but not inspirato design system: referencing statically
export const TagIcon = ({ iconSize, width, height, fill, ...rest }: CustomIconProps) => (
	<Svg iconSize={iconSize} width={width} height={height} fill={fill} {...rest}>
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.5,21.3l8.83-8.83a2,2,0,0,0,.58-1.41V3.89a2,2,0,0,0-2-2H12.74a2,2,0,0,0-1.41.59L2.5,11.31a2,2,0,0,0,0,2.83l7.17,7.17A2,2,0,0,0,12.5,21.3ZM3.91,12.72l8.83-8.83h7.17v7.17l-8.83,8.83Z"/><circle cx="16.41" cy="7.39" r="1.5"/></svg>
	</Svg>
);

export const CopyIcon = ({ iconSize, width, height, fill, ...rest }: CustomIconProps) => (
	<Svg iconSize={iconSize} width={width} height={height} fill={fill} {...rest}>
		<svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="#606060" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="215.993 183.995 215.993 39.994 71.986 39.994" fill="none" stroke="#606060" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><rect x="39.98633" y="71.99512" width="144.00586" height="144" stroke-width="16" stroke="#606060" stroke-linecap="round" stroke-linejoin="round" fill="none"></rect></svg>
	</Svg>
);

export const InspiratoSelectLogo = ({ iconSize, width, height, fill, ...rest }: CustomIconProps) => (
	<Svg iconSize={iconSize} width={width} height={height} fill={fill} {...rest}>
		<svg width="132" height="79" viewBox="40.31 24.2 486.05 172.69">
			<path d="M163.57,63.19c-5.88-2.3-19.53-5.51-19.53-13.57c0,0-0.62-9.71,14.68-10.16c0,0,15.61-0.33,20.19,11.84h1.39V40.19 l-1.39,0c-0.24,1.36-1.46,2.5-2.41,2.5c-1.68,0.3-4.13-1.5-5.15-1.91c-0.31-0.15-0.63-0.3-0.98-0.46c0,0-0.02-0.01-0.02-0.01 l0,0c-2.8-1.23-6.8-2.36-11.79-2.34l-1.36,0c-12.11,0-19.63,6.09-19.63,15.91c0,0-0.79,7.6,11.7,12.99 c12.49,5.38,12.49,5.38,12.49,5.38c9.71,4.66,14.08,7.25,14.08,15.73c0,6.83-7.32,11.83-15.92,11.83 c-18.44,0-23.6-14.52-23.6-14.52h-1.39v12.4h1.32c0.72-1,1.36-1.48,1.87-1.71c0,0,1.68-0.92,3.83,0.34 c2.15,1.26,9.97,5.86,18.16,5.86c12.88,0,24.19-7.95,24.19-18.83C184.32,71.31,173.82,67.2,163.57,63.19z" />
			<path d="M40.31,41.01c2.63,0,2.63,2.05,2.63,3.42v51.24c0,1.37,0,3.42-2.63,3.42v1.64h14.36v-1.64c-2.63,0-2.63-2.05-2.63-3.42 V44.43c0-1.37,0-3.42,2.63-3.42v-1.64H40.31V41.01z" />
			<path d="M228.97,39.45l-21.24-0.2h-14.36v1.64c2.63,0,2.63,2.05,2.63,3.42v51.4c0,1.37,0,3.42-2.63,3.42v1.64h14.5v-1.64 c-2.63,0-2.63-2.05-2.63-3.42l0.06-7.83v-9.65h23.39c14.57,0,23.1-7.63,23.1-19.44C251.79,49.17,245.35,39.45,228.97,39.45z M219.98,76.16H205.3v-29.8c0-5.09,9.91-5.09,15.24-5.09c10.43,0,21.84,5.22,21.84,16.89 C242.38,70.59,230.69,76.16,219.98,76.16z" />
			<path d="M259.03,40.96c2.63,0,2.63,2.05,2.63,3.42v51.3c0,1.37,0,3.42-2.63,3.42v1.64h14.36v-1.64c-2.63,0-2.63-2.05-2.63-3.42 v-51.3c0-1.37,0-3.42,2.63-3.42v-1.64h-14.36V40.96z" />
			<path d="M467.2,45.69l-2.07-6.44l-0.89,0.01l-13.07,0h-36.92l-3.52,0l-8.22-0.01l-2.07,6.44l1.69,0 c1.55-3.66,7.1-4.35,12.31-4.35h14.13v46.61v7.71c0,1.37,0,3.42-2.63,3.42v1.64h14.36v-1.64c-2.63,0-2.63-2.05-2.63-3.42 l0.09-54.32h9.69l5.74,0c5.22,0,10.76,0.68,12.31,4.35L467.2,45.69z" />
			<path d="M120.77,40.93c2.63,0,2.63,2.05,2.63,3.42l-0.03,9.25V85.3c0,0-0.14,0.97-1.27,1.26c-0.9,0.22-1.59-0.46-1.59-0.46 L73.68,39.27l-2.05,0h-7.8v1.64c2.63,0,2.63,2.05,2.63,3.42v51.4c0,1.37,0,3.42-2.63,3.42v1.64h8.25v-1.64 c-2.63,0-2.63-2.05-2.63-3.42l0-10.82c0,0,0-33.97,0-33.97c0,0-0.03-0.95,0.9-1.45c0.93-0.5,1.77,0.17,1.95,0.39l50.69,50.91 h3.39V52.76l0,42.38V44.35c0-1.37,0-3.42,2.63-3.42v-1.64h-8.25V40.93z" />
			<path d="M407.08,95.81l-22.61-56.48l-6.64-0.07l-18.38,49.98c-1.77,4.76-3.56,7.93-6.45,8.94c-2.73,0.95-7.35,0-7.35,0 c-17.35-5.46-19.22-17.46-19.41-19.93l-0.02-0.24l0.24-0.02l-0.66,0.01c12.07-1.31,19.04-8.54,19.04-19.21 c0-9.63-6.44-19.35-22.82-19.35l-21.24-0.2h-14.36v1.64c2.63,0,2.63,2.05,2.63,3.42v51.41c0,1.37,0,3.42-2.63,3.42v1.64h14.5 v-1.64c-2.63,0-2.63-2.05-2.63-3.42l0.06-7.85v-9.65h18.5c6.26,13.51,14.19,22.52,32.47,22.6l17.1-0.08v-1.68 c-5.09-0.79-4.97-4.06-4.97-5.96c0-1.31,0.23-2.63,0.65-3.81l2.62-7.32h27.94l2.9,7.53c0.76,1.85,1.15,3.4,1.15,4.62l-0.01,0.53 c0,1.37-0.6,4.47-3.23,4.47v1.64h18.9v-1.64C409.74,99.15,407.9,97.7,407.08,95.81z M298.34,76.16v-29.8 c0-5.09,9.91-5.09,15.24-5.09c10.43,0,21.84,5.22,21.84,16.89c0,12.44-11.69,18.01-22.4,18.01H298.34z M365.48,79.91 l11.36-31.18c0,0,0.34-0.9,1.41-0.99c1.07-0.09,1.56,0.96,1.56,0.96l12.08,31.21H365.48z" />
			<path d="M524.03,58.24c-6.13-17.1-22.34-25.04-38.74-19.07c-16.78,6.11-25.02,24.38-18.75,41.59 c6.48,17.8,22.25,25.37,39.7,19.02C514.32,96.84,532.86,82.9,524.03,58.24z M514.03,81.34c-4.42,7.3-9.39,13.1-15.47,15.31 c-5.96,2.17-12.06,1.53-17.64-1.85c-12.19-7.39-13.19-22.83-3.57-38.71c3.96-6.53,8.23-11.88,13.87-13.93 c6.24-2.27,12.82-1.49,19.03,2.27C521.96,51.52,523.17,66.25,514.03,81.34z" />
			<path d="M159.89,208.05c-2.8-1.4-4.48-3.23-5.04-5.48c-0.55-2.25,0.39-4.51,2.85-6.79c2.57-1.93,8.14-4.33,16.7-7.19 c8.56-2.86,17.34-5.61,26.34-8.24c-2.4-2.92-5.74-6.24-10.04-9.95c-4.29-3.71-8.52-7.29-12.67-10.74 c-3.92-3.21-6.95-5.78-9.12-7.71c-3.45-3.1-5.93-6.34-7.45-9.73c-1.52-3.39-0.88-6.72,1.93-9.99c3.45-3.39,8.75-6.06,15.91-8.02 c7.16-1.96,14.71-3.26,22.66-3.9c8.12-0.58,15.21-0.48,21.26,0.31c6.05,0.79,9.6,2.27,10.65,4.43c0.93,2.16,0.41,4.25-1.58,6.27 c-1.99,2.02-5.81,4.05-11.48,6.09c-1.11,0.29-2.09,0.09-2.94-0.61c-0.85-0.7-1.31-1.52-1.4-2.45c-0.09-0.93,0.42-1.64,1.53-2.1 c2.45-0.82,4.56-1.52,6.31-2.1c2.69-0.82,4.56-1.53,5.61-2.15c1.05-0.61,1.34-1.45,0.88-2.5c-0.64-0.82-3.92-1.48-9.82-1.97 c-5.9-0.5-12.8-0.54-20.69-0.13c-7.89,0.41-15.12,1.53-21.69,3.37c-6.57,1.84-10.85,4.65-12.84,8.41 c-1.99,3.77-0.03,8.75,5.87,14.94c4.44,4.38,9.12,8.56,14.02,12.53c4.5,3.68,8.85,7.54,13.06,11.57c1.81,1.81,3.36,3.62,4.65,5.43 l4.21-1.23c7.6-2.22,12.48-3.71,14.64-4.47c0.06-0.06,0.13-0.07,0.22-0.04c0.09,0.03,0.16,0.1,0.22,0.22 c0.06,0.06,0.06,0.15,0,0.26c-0.06,0.12-0.12,0.18-0.18,0.18c-2.05,0.76-6.69,2.19-13.94,4.29c-0.23,0.06-0.5,0.15-0.79,0.26 l-3.94,1.14c2.75,4.09,3.59,7.86,2.54,11.31s-3.45,6.49-7.19,9.12c-2.75,1.93-6.49,3.77-11.22,5.52s-9.64,2.91-14.73,3.46 C168.13,210.23,163.69,209.69,159.89,208.05z M159.1,196.92c-2.4,2.1-3.18,3.77-2.37,5c0.82,1.23,2.37,2.31,4.65,3.24 c3.68,1.64,7.81,2.23,12.4,1.8c4.59-0.44,9.03-1.45,13.32-3.02s7.81-3.33,10.56-5.26c3.16-2.28,5.19-4.86,6.09-7.76 c0.91-2.89,0.16-6.06-2.23-9.51c0-0.06-0.03-0.12-0.09-0.18c-0.06-0.06-0.12-0.15-0.18-0.26c-9,2.69-17.65,5.54-25.94,8.55 C167.02,192.52,161.61,194.99,159.1,196.92z" />
			<path d="M233.78,167.47c0.53,1.64,0.06,3.21-1.4,4.73c-1.46,1.52-3.13,2.73-5,3.64c-1.87,0.91-3.19,1.3-3.94,1.18 c-0.06,0.29-0.09,0.56-0.09,0.79c0,0.23,0,0.5,0,0.79c0.12,1.11,0.47,1.99,1.05,2.63c1.93,2.1,4.78,3.01,8.55,2.72 c3.77-0.29,7.71-1.64,11.83-4.03c4.12-2.4,7.44-4.53,9.95-6.4c0.12-0.06,0.22-0.09,0.31-0.09c0.09,0,0.16,0.06,0.22,0.18 c0.06,0.06,0.07,0.15,0.04,0.26c-0.03,0.12-0.07,0.21-0.13,0.26c-2.81,2.05-6.53,4.34-11.18,6.88c-4.65,2.54-9.07,3.99-13.28,4.34 s-7.33-0.55-9.38-2.72c-1.87-2.69-2.18-5.67-0.92-8.94c1.26-3.27,3.08-5.75,5.48-7.45c0.47-0.29,1.21-0.6,2.23-0.92 c1.02-0.32,2.06-0.36,3.11-0.13C232.29,165.42,233.14,166.18,233.78,167.47z M228.78,169.75c-1.11,0.76-2.15,1.7-3.11,2.8 c-0.96,1.11-1.65,2.31-2.06,3.59c0.35-1.05,0.75-1.74,1.18-2.06c0.44-0.32,0.86-0.47,1.27-0.44c0.41,0.03,0.79,0.13,1.14,0.31 c0.18,0.06,0.34,0.12,0.48,0.18c0.15,0.06,0.28,0.06,0.39,0c1.4-0.76,2.45-1.59,3.16-2.5c0.7-0.91,1.05-1.67,1.05-2.28 c0-0.61-0.32-0.86-0.96-0.75C230.57,168.78,229.72,169.16,228.78,169.75z" />
			<path d="M259.02,172.03c-0.64,2.16-1.14,4.29-1.49,6.4c-0.82,4.44-0.31,7.73,1.53,9.86c1.84,2.13,5.83,1.94,11.96-0.57 c4.67-2.04,9.04-4.5,13.1-7.36c4.06-2.86,6.76-4.94,8.11-6.22c0.06-0.06,0.13-0.09,0.22-0.09c0.09,0,0.19,0.03,0.31,0.09 c0.06,0.12,0.09,0.22,0.09,0.31c0,0.09-0.03,0.16-0.09,0.22c-1.4,1.29-4.15,3.37-8.24,6.27c-4.09,2.89-8.47,5.39-13.15,7.49 c-6.95,2.8-11.73,3.2-14.33,1.18c-2.6-2.02-3.77-5.3-3.51-9.86c0.26-4.56,1.2-9.25,2.8-14.07c1.61-4.82,3.17-8.63,4.69-11.44 c0.99-1.93,2.07-4,3.24-6.22l1.23-2.19c1.34-2.1,2.69-4.35,4.03-6.75l1.4-2.1c0.06-0.12,0.12-0.2,0.18-0.26 c5.32-8.77,11.04-15.86,17.18-21.3c0.99-0.76,2.31-1.31,3.94-1.67c0.99-0.18,1.9-0.2,2.72-0.09c1.28,0.29,2.45,0.96,3.51,2.02 c1.52,1.87,1.43,4.82-0.26,8.85c-1.7,3.86-4.41,8.27-8.15,13.24C284.09,145.59,273.75,157.01,259.02,172.03z M259.37,170.89 c14.32-14.84,24.34-26.03,30.06-33.57c3.74-4.91,6.43-9.29,8.06-13.15c1.58-3.68,1.69-6.34,0.35-7.98 c-0.35-0.35-0.89-0.6-1.62-0.75c-0.73-0.15-1.65,0.22-2.76,1.1c-5.08,4.68-10.43,11.25-16.04,19.72h0.09l-1.67,2.28 c-1.05,1.7-1.86,2.95-2.41,3.77c-0.56,0.82-0.89,1.34-1.01,1.58c-0.18,0.35-0.38,0.7-0.61,1.05l-1.49,2.37 c-1.11,1.87-2.05,3.51-2.8,4.91l0.26-0.26l-1.23,2.1l-0.09,0.09l-0.35,0.53c-0.12,0.18-0.21,0.38-0.26,0.61 c-1.17,2.16-2.66,5.46-4.47,9.9C260.63,167.06,259.96,168.96,259.37,170.89z" />
			<path d="M305.74,167.47c0.53,1.64,0.06,3.21-1.4,4.73c-1.46,1.52-3.13,2.73-5,3.64c-1.87,0.91-3.19,1.3-3.94,1.18 c-0.06,0.29-0.09,0.56-0.09,0.79c0,0.23,0,0.5,0,0.79c0.12,1.11,0.47,1.99,1.05,2.63c1.93,2.1,4.78,3.01,8.55,2.72 c3.77-0.29,7.71-1.64,11.83-4.03c4.12-2.4,7.44-4.53,9.95-6.4c0.12-0.06,0.22-0.09,0.31-0.09c0.09,0,0.16,0.06,0.22,0.18 c0.06,0.06,0.07,0.15,0.04,0.26c-0.03,0.12-0.07,0.21-0.13,0.26c-2.81,2.05-6.53,4.34-11.18,6.88c-4.65,2.54-9.07,3.99-13.28,4.34 s-7.33-0.55-9.38-2.72c-1.87-2.69-2.18-5.67-0.92-8.94c1.26-3.27,3.08-5.75,5.48-7.45c0.47-0.29,1.21-0.6,2.23-0.92 c1.02-0.32,2.06-0.36,3.11-0.13C304.25,165.42,305.1,166.18,305.74,167.47z M300.75,169.75c-1.11,0.76-2.15,1.7-3.11,2.8 c-0.96,1.11-1.65,2.31-2.06,3.59c0.35-1.05,0.75-1.74,1.18-2.06c0.44-0.32,0.86-0.47,1.27-0.44c0.41,0.03,0.79,0.13,1.14,0.31 c0.18,0.06,0.34,0.12,0.48,0.18c0.15,0.06,0.28,0.06,0.39,0c1.4-0.76,2.45-1.59,3.16-2.5c0.7-0.91,1.05-1.67,1.05-2.28 c0-0.61-0.32-0.86-0.96-0.75C302.53,168.78,301.68,169.16,300.75,169.75z" />
			<path d="M341.94,169.57c-0.53,0.88-1.08,1.4-1.67,1.58c-0.58,0.18-1.11,0.15-1.58-0.09c-0.47-0.23-0.77-0.57-0.92-1.01 c-0.15-0.44-0.07-0.83,0.22-1.18c0.06-0.18,0.2-0.39,0.44-0.66c0.23-0.26,0.5-0.51,0.79-0.75c0.29-0.35,0.57-0.67,0.83-0.96 c0.26-0.29,0.41-0.54,0.44-0.75c0.03-0.2-0.1-0.34-0.39-0.39c-1.17-0.12-2.64,0.75-4.43,2.59c-1.78,1.84-3.37,4.06-4.78,6.66 c-1.4,2.6-2.05,4.95-1.93,7.06c0.12,2.1,1.5,3.36,4.16,3.77c2.66,0.41,7.11-0.67,13.37-3.24c3.33-1.46,6.32-2.97,8.98-4.51 c2.66-1.55,4.63-2.85,5.92-3.9c0.12-0.06,0.22-0.09,0.31-0.09s0.16,0.06,0.22,0.18c0.06,0.06,0.09,0.13,0.09,0.22 c0,0.09-0.06,0.19-0.18,0.31c-1.29,1.05-3.29,2.37-6,3.94c-2.72,1.58-5.71,3.1-8.98,4.56c-0.12,0.06-0.23,0.09-0.35,0.09 c-1.75,0.82-4.03,1.72-6.84,2.72c-2.81,0.99-5.57,1.52-8.28,1.58c-2.72,0.06-4.78-0.91-6.18-2.89c-1.52-2.75-1.39-5.84,0.39-9.29 c1.78-3.45,4.15-6.34,7.1-8.68c2.95-2.34,5.42-3.21,7.41-2.63c1.23,0.53,1.96,1.36,2.19,2.5 C342.52,167.42,342.41,168.52,341.94,169.57z" />
			<path d="M371.48,148.01c-7.95,0.99-15.05,1.67-21.3,2.02c-7.07,0.41-11.51-0.41-13.32-2.45c-0.35-0.58-0.35-1.08,0-1.49 c0.35-0.41,0.8-0.66,1.36-0.74c0.56-0.09,0.92,0.16,1.1,0.74c0.35,2.4,3.58,3.36,9.69,2.89c6.11-0.47,13.57-1.46,22.39-2.98 c0.41-0.06,0.85-0.12,1.32-0.18c1.23-2.1,2.54-4.35,3.94-6.75h-0.09l1.4-2.1c0.06-0.12,0.12-0.2,0.17-0.26 c5.32-8.77,11.04-15.86,17.18-21.3c0.99-0.76,2.31-1.3,3.94-1.62c1.63-0.32,3.01-0.19,4.12,0.39c1.11,0.58,1.37,1.84,0.79,3.77 c-1.81,4.44-5.49,9.76-11.04,15.95l-0.88,1.05c-0.23,0.18-0.44,0.38-0.61,0.61l-2.19,2.45c-2.05,2.28-4.03,4.38-5.96,6.31 c8.94-1.05,16.54-1.11,22.79-0.18c3.21,0.76,5.07,1.8,5.57,3.11c0.5,1.31-0.48,2.23-2.94,2.76c-0.94,0.06-1.71-0.03-2.32-0.26 c-0.61-0.23-1.21-0.55-1.8-0.96c-0.94-0.7-2.43-1.37-4.47-2.02c-2.05-0.64-5.32-0.85-9.82-0.61c-3.1,0.23-6.28,0.53-9.55,0.88 c-0.41,0.35-0.82,0.73-1.23,1.14c-2.98,2.92-5.05,4.91-6.22,5.96l-0.35,0.53c-0.12,0.18-0.21,0.38-0.26,0.61 c-1.17,2.16-2.66,5.46-4.47,9.9c-1.81,4.44-3.11,8.87-3.9,13.28c-0.79,4.41-0.26,7.68,1.58,9.82c1.84,2.13,5.83,1.94,11.96-0.57 c4.67-2.04,9.04-4.5,13.1-7.36c4.06-2.86,6.76-4.94,8.11-6.22c0.06-0.06,0.13-0.09,0.22-0.09c0.09,0,0.19,0.03,0.31,0.09 c0.06,0.12,0.09,0.22,0.09,0.31c0,0.09-0.03,0.16-0.09,0.22c-1.4,1.29-4.15,3.37-8.24,6.27c-4.09,2.89-8.47,5.39-13.15,7.49 c-6.96,2.8-11.73,3.2-14.33,1.18c-2.6-2.02-3.77-5.3-3.51-9.86c0.26-4.56,1.2-9.25,2.81-14.07c1.61-4.82,3.17-8.63,4.69-11.44 C369.05,152.31,370.19,150.23,371.48,148.01z M377.35,147.31c-1.11,1.87-2.05,3.51-2.8,4.91c0.99-0.93,2.75-2.63,5.26-5.08h-0.09 l0.09-0.09C378.99,147.16,378.17,147.25,377.35,147.31z M382.44,144.42c1.87-1.93,3.86-4.03,5.96-6.31h-0.09l2.37-2.45l0.26-0.26 c2.92-3.27,5.55-6.49,7.89-9.64c2.34-3.16,3.8-5.81,4.38-7.98c0.41-1.69,0.34-2.54-0.22-2.54c-0.56,0-1.39,0.44-2.5,1.31 c-5.08,4.68-10.43,11.25-16.04,19.72h0.09l-1.67,2.28c-1.52,2.34-2.86,4.47-4.03,6.4C380.07,144.77,381.27,144.59,382.44,144.42z" />
		</svg>
	</Svg>
);
