import React, { Fragment } from 'react';
import { FooterColumnProps } from './FooterColumn/FooterColumnProps';
import { MainFooter } from './MainFooter';
import { Subfooter } from './Subfooter';
import './footer.scss';

export interface FooterLink {
	link: {
		canDisplayAsImageLink: boolean;
		canDisplayAsTextLink: boolean;
		linkTitle: string;
		newWindow: boolean;
		url: string;
	};
	componentName: string;
}

export interface FooterColumns {
	columnHeader: string;
	columnNavigationLinks: FooterLink[];
}

export interface FooterProps {
	footerColumns: FooterColumns[];
}

export const Footer: React.FC<FooterProps> = ({ footerColumns }) => {
	const mappedLinks = footerColumns.map(({ columnNavigationLinks = [] }) => {
		return columnNavigationLinks.map(({ link, componentName }) => ({ ...link, componentName }));
	});

	const mappedHeaders = footerColumns.map(({ columnHeader }) => columnHeader);

	const columnData: FooterColumnProps[] = [
		{ columnHeader: mappedHeaders[0], links: mappedLinks[0] },
		{ columnHeader: mappedHeaders[1], links: mappedLinks[1] },
		{ columnHeader: mappedHeaders[2], links: mappedLinks[2] },
		{ columnHeader: mappedHeaders[3], links: mappedLinks[3] },
	];

	return (
		<Fragment>
			<footer className='responsive-footer'>
				<MainFooter footerColumns={columnData} componentName='' />
				<Subfooter />
			</footer>
		</Fragment>
	);
};
