import root from 'window-or-global';
import { css } from 'styled-components';
import { textBaseSerif, fontSerif } from './style-mixins/text';
import { textColorMedium, black } from './variables/colors';
import { spaceBase, spaceSmall, spaceMedium } from './variables/spaces';
import 'focus-visible';

/* eslint-disable no-bitwise */
// We should find a library for this, but I haven't been able to find one yet.

export function mix(hexColor1: string) {
	return hexColor1;
}

function lightenColor(color: string, percentage: number) {
	let useHash = false;
	let slicedColor;

	if (color[0] === '#') {
		slicedColor = color.slice(1);
		useHash = true;
	}

	const decValue = parseInt(slicedColor, 16);
	const r = (decValue >> 16) + percentage;
	const g = ((decValue >> 8) & 0x00ff) + percentage;
	const b = (decValue & 0x0000ff) + percentage;
	const newColor = g | (b << 8) | (r << 16);

	return `${useHash ? '#' : ''}${newColor.toString(16)}`;
}

// from https://stackoverflow.com/questions/13806483/increase-or-decrease-color-saturation
function saturateColor(hexColor, percentage) {
	const saturationFloat = percentage / 100;
	const rgbIntensityFloat = [
		parseInt(hexColor.substr(1, 2), 16) / 255,
		parseInt(hexColor.substr(3, 2), 16) / 255,
		parseInt(hexColor.substr(5, 2), 16) / 255,
	];

	const rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort((a, b) => a - b);
	const maxIntensityFloat = rgbIntensityFloatSorted[2];
	const mediumIntensityFloat = rgbIntensityFloatSorted[1];
	const minIntensityFloat = rgbIntensityFloatSorted[0];

	if (maxIntensityFloat === minIntensityFloat) {
		// All colors have same intensity, which means
		// the original color is gray, so we can't change saturation.
		return hexColor;
	}

	// New color max intensity wont change. Lets find medium and weak intensities.
	let newMediumIntensityFloat;
	const newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);

	if (mediumIntensityFloat === minIntensityFloat) {
		// Weak colors have equal intensity.
		newMediumIntensityFloat = newMinIntensityFloat;
	} else {
		// Calculate medium intensity with respect to original intensity proportion.
		const intensityProportion =
			(maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);

		newMediumIntensityFloat =
			(intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
	}

	const newRgbIntensityFloat = [];
	const newRgbIntensityFloatSorted = [newMinIntensityFloat, newMediumIntensityFloat, maxIntensityFloat];

	// We've found new intensities, but we have then sorted from min to max.
	// Now we have to restore original order.
	rgbIntensityFloat.forEach(originalRgb => {
		const rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);

		newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
	});

	const floatToHex = val => {
		return `0${Math.round(val * 255).toString(16)}`.substr(-2);
	};
	const rgb2hex = rgb => {
		return `#${floatToHex(rgb[0])}${floatToHex(rgb[1])}${floatToHex(rgb[2])}`;
	};

	return rgb2hex(newRgbIntensityFloat);
}

export function lighten(hexColor: string, percentage: number) {
	return lightenColor(hexColor, percentage);
}

export function darken(hexColor: string, percentage: number) {
	return lightenColor(hexColor, -percentage);
}

export function saturate(hexColor: string, percentage: number) {
	return saturateColor(hexColor, percentage);
}

export function desaturate(hexColor: string, percentage: number) {
	return saturateColor(hexColor, -percentage);
}

/* Backup missing images, hosted in umbraco folder "Design System Assets - DO NOT DELETE" */
let REACT_APP_IMAGE_SERVER = 'https://cms.inspirato.com';

if (root.process && root.process.env) {
	({ REACT_APP_IMAGE_SERVER } = process.env);
}
export const brokenImgHoriz = `${REACT_APP_IMAGE_SERVER}/ImageGen.ashx?image=/media/9416839/broken-img-horiz.png`;
export const brokenImg16x9 = `${REACT_APP_IMAGE_SERVER}/ImageGen.ashx?image=/media/9416838/broken-img-16x9.png`;

/* Truncate text blocks... */
export const truncateText = css`
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

/* Visually hidden but still available for screen readers... */
export const visuallyHidden = css`
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap;
`;

const globalFontStyle = css`
	${fontSerif}
	${textBaseSerif}
	${textColorMedium}
	line-height: 27px;
`;

export const richText = css`
	text-align: left;
	${globalFontStyle}

	p {
		color: inherit;
		margin-bottom: ${spaceBase};
		margin-top: ${spaceBase};
	}

	li + li {
		margin-top: ${spaceBase};
	}

	ul,
	ol {
		color: inherit;
		line-height: 24px;
		margin: auto;
		margin-left: 0;
	}

	ul {
		list-style: none;
		padding: ${spaceBase} 0;

		li {
			fill: ${black};
			padding-left: ${spaceMedium};
			position: relative;

			&:before {
				content: '\\2022';
				left: 0;
				position: absolute;
				top: 0;
				width: 10px;
			}
		}
	}

	ol {
		padding: ${spaceBase} 0 ${spaceBase} ${spaceBase};

		li {
			padding-left: ${spaceSmall};
		}
	}
`;

export const pluralize = (count, singular, plural) => `${count} ${count === 1 ? singular : plural}`;

/* Anchor Spacing
 *   When linking to an element on a page, add this function to the styles
 *   of that element to provide spacing from the top of the page and
 *   the element.
 *   - pixels (Number): pixel value from top of page plus the element's
 *     font size */
export const anchorSpacing = pixels => css`
	&:before {
		content: '';
		display: block;
		visibility: hidden;
		margin-top: calc((${pixels}px + 1em) * -1);
		height: calc(${pixels}px + 1em);
		pointer-events: none;
	}
`;
