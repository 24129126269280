import { css } from 'styled-components';

/* Transparencies & Shadows
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/**
 * Dark transparencies:
 * Use for text shadow, dark scrim colors and box shadows.
 */
export const transparentDark00 = `rgba(0, 0, 0, 0.0)`;
export const transparentDark10 = `rgba(0, 0, 0, 0.1)`;
export const transparentDark15 = `rgba(0, 0, 0, 0.15)`;
export const transparentDark20 = `rgba(0, 0, 0, 0.2)`;
export const transparentDark30 = `rgba(0, 0, 0, 0.3)`;
export const transparentDark50 = `rgba(0, 0, 0, 0.5)`;
export const transparentDark70 = `rgba(0, 0, 0, 0.7)`;
export const transparentDark80 = `rgba(0, 0, 0, 0.8)`;
export const transparentDark90 = `rgba(0, 0, 0, 0.9)`;

/**
 * Light transparencies:
 * Use for light scrim colors.
 */
export const transparentLight00 = `rgba(255, 255, 255, 0.0)`;
export const transparentLight80 = `rgba(255, 255, 255, 0.8)`;
export const transparentLight100 = `rgba(255, 255, 255, 1)`;

/**
 * Gradient transparencies:
 * Use for dark gradient scrims.
 */
export const transparentGradientDark = css`
	background-image: linear-gradient(180deg, ${transparentDark00} 66%, ${transparentDark50} 100%);
`;

export const transparentGradientDarkHover = css`
	background-image: linear-gradient(180deg, ${transparentDark50} 66%, ${transparentDark70} 100%);
`;

export const transparentEaseGradient = css`
	background-image: linear-gradient(
		to bottom,
		hsla(0, 0%, 0%, 0) 0%,
		hsla(0, 0%, 0%, 0.005) 11.2%,
		hsla(0, 0%, 0%, 0.02) 21%,
		hsla(0, 0%, 0%, 0.043) 29.6%,
		hsla(0, 0%, 0%, 0.075) 37.1%,
		hsla(0, 0%, 0%, 0.113) 43.7%,
		hsla(0, 0%, 0%, 0.158) 49.6%,
		hsla(0, 0%, 0%, 0.208) 55%,
		hsla(0, 0%, 0%, 0.262) 60%,
		hsla(0, 0%, 0%, 0.321) 64.8%,
		hsla(0, 0%, 0%, 0.382) 69.6%,
		hsla(0, 0%, 0%, 0.445) 74.6%,
		hsla(0, 0%, 0%, 0.509) 80%,
		hsla(0, 0%, 0%, 0.573) 85.9%,
		hsla(0, 0%, 0%, 0.637) 92.5%,
		hsla(0, 0%, 0%, 0.7) 100%
	);
`;

export const transparentGradientLight = css`
	background: linear-gradient(
		-90deg,
		${transparentLight100} 0%,
		${transparentLight100} 24%,
		${transparentLight00} 100%
	);
`;

/**
 * Shadows:
 * Use for box shadows.
 */
export const boxShadow1 = css`
	box-shadow: 0 1px 4px ${transparentDark15};
`;
export const boxShadow2 = css`
	box-shadow: 0 2px 8px ${transparentDark15};
`;
export const boxShadow3 = css`
	box-shadow: 0 4px 16px ${transparentDark15};
`;
export const boxShadow4 = css`
	box-shadow: 0 8px 24px ${transparentDark15};
`;
export const boxShadow5 = css`
	box-shadow: 0 12px 32px ${transparentDark15};
`;

/**
 * Text Shadows:
 * Use for text shadows.
 */
export const textShadow = css`
	text-shadow: 0 1px 2px ${transparentDark30};
`;

/**
 * SVG Shadows:
 * Use for svg icon shadows.
 */
export const svgShadowLight = css`
	filter: drop-shadow(0 1px 2px ${transparentDark20});
`;
export const svgShadow = css`
	filter: drop-shadow(0 1px 2px ${transparentDark30});
`;
