import { css } from 'styled-components';
import { mix } from '../utils';

/**
 * Primary & Secondary Brand Colors:
 * 1. Used for primary buttons
 * 2. Used for secondary buttons.
 */
export const black = '#000';
// export const blackHover = lighten(black, 17); /* 1 */
export const blackHover = '#2b2b2b'; /* Will update to correct color  */
export const white = '#fff';
// export const whitePressed = `rgba(${white}, 0.9)`; /* 2 */
export const whitePressed = `rgba(255, 255, 255, 0.9)`; /* Will update to correct color  */

/**
 * Tertiary Brand Colors:
 * Used occasionally for color emphasis
 */
export const blue = '#bbced9';
export const marigold = '#e3c17a';
export const mint = '#9fd4b7';
export const yellow = '#edd900';
/**
 * Neutral colors:
 * 1. Backgrounds only.
 * 2. All basic UI, input outlines, lines, etc.
 */
export const paleBlue = '#f1f4f8'; /* 1 */
export const gray = '#c5c5c5'; /* 2 */
export const grayLight = 'rgba(197, 197, 197, 0.15)';
export const grayMedium = '#858585';
export const grayDark = '#606060';

/* Utility Colors */
export const info = paleBlue;
export const infoLight = mix(info);
export const success = '#ddeecc';
export const successLight = mix(success); /* Will update to correct color */
export const warning = '#edd900';
export const warningLight = mix(success); /* Will update to correct color */
export const error = '#dd3310';
export const errorLight = mix(error); /* Will update to correct color */
// export const disabled = lighten(black, 75.00);
export const disabled = gray; /* Will update to correct color */

// Gradient for skeleton loading animation
export const loaderSkeletonGradient = `
	rgba(197, 197, 197, 0.2),
	rgba(197, 197, 197, 0.2) 25%,
	rgba(197, 197, 197, 0.05),
	rgba(197, 197, 197, 0.2) 75%,
	rgba(197, 197, 197, 0.2)`;

/**
 * Common text colors:
 * 1. Lightest colored text.
 * 2. Medium colored text.
 * 3. Dark colored text.
 * 4. Text to go over a disabled button.
 */
export const textColorLight = css`
	color: ${grayMedium};
`; /* 1 */
export const textColorMedium = css`
	color: ${grayDark};
`; /* 2 */
export const textColorDark = css`
	color: ${black};
`; /* 3 */
export const textColorDisabled = css`
	color: ${grayDark};
`; /* 4 */

/**
 * Link colors:
 * 1. Links are slighly modified from base color system to comply with WCAG accessability guidelines.
 * 2. NOTE: THESE PREVIOUS SASS FUNCTIONS ARE NOT WORKING. NEED TO REWRITE THEM TO WORK
 * 3. NOTE: Hover states need to have updated functions, specifically mint hover and marigold hover
 */

export const primaryLinkColor = '#5991b2'; // darken(saturate(blue, 8.32), 26.86); /* #5991b2 */
export const primaryLinkColorHover = '#82adc5'; // darken(saturate(blue, 8.32), 15); /* #82adc5 */

export const mintLinkColor = '#66ad86'; // darken(desaturate(mint, 7.92), 18.82)
export const mintLinkColorHover = '#66ad86'; // darken(desaturate(mint, 7.92), 11.82)

export const marigoldLinkColor = '#b28e45'; // darken(desaturate(marigold, 7.92), 18.82)
export const marigoldLinkColorHover = '#b28e45'; // darken(desaturate(marigold, 7.92), 11.82)

/**
 * Outline color for global focus outline:
 */
export const focusOutlineColor = '#82adc5';

/**
 * Hover states for items:
 * Use for dropdown menus hover background color, calendar blocks, etc.
 */

export const itemHover = `rgba(187, 206, 217, 0.25)`; // rgba(${blue}, 0.25)

/**
 * Remove "Mobile Tap/Flicker"
 * 1. Changes default "tap" color to be transparent instead of light blue.
 */
export const tapTransparent = css`
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`; /* 1 */

/* Other Variables & Mixins
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/**
 * Dividers:
 * Use for horizontal & vertical dividers/lines/rules.
 */
export const dividerWhite = `1px solid ${white}`; // 1px solid rgba(${gray}, 0.3)`;
export const dividerGray = `1px solid rgba(197, 197, 197, 0.5)`; // 1px solid rgba(${gray}, 0.5)`;
export const dividerGrayLight = `1px solid rgba(197, 197, 197, 0.3)`; // 1px solid rgba(${gray}, 0.3)`;
export const dividerMedium = `2px solid ${black}`;
export const dividerThick = `3px solid ${black}`;
