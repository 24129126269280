import styled, { css } from 'styled-components';
import { fontSans, fontSerif, textBaseSerif, textTiny } from '../../design-system/style-mixins/text';
import { textColorMedium } from '../../design-system/variables/colors';

const base = css`
	${fontSerif}
	${textBaseSerif}
	line-height: 27px;
`;

const small = css`
	${fontSans}
	${textTiny}
	line-height: 14px;
`;

const subLabel = css`
	${textTiny}
	line-height: 14px;
`;

const sizeStyles = {
	base,
	small,
	subLabel
};

/**
 * Paragraphs:
 * Base is standard paragraph format.
 * Small is used for very small legal text, possibly error messages, etc.
 */
export const Paragraph = styled.p<{ size?: string }>`
	${textColorMedium};
	${(props) => sizeStyles[props.size] || sizeStyles.base};
`;
